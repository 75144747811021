import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "black",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const images = {
  unity: "images/works/unitylogo.png",
  react: "images/works/reactlogo.png",
};

function Portfolio({ portfolio }) {
  const {
    category,
    title,
    image,
    popupLink,
    link,
    description,
    framework,
    video,
    dDocument,
    gitHub,
  } = portfolio;
  const [toggler, setToggler] = useState(false);

  const handleLightbox = (e) => {
    if (!link) {
      e.preventDefault();
      setToggler(!toggler);
    }
  };

  const handleIcon = () => {
    if (link) {
      return <i className="icon-link"></i>;
    } else if (popupLink) {
      if (popupLink.length > 1) {
        if (popupLink.toString().match(/youtube/g)) {
          return <i className="icon-camrecorder"></i>;
        }
        return <i className="icon-picture"></i>;
      } else if (popupLink.toString().match(/youtube/g)) {
        return <i className="icon-camrecorder"></i>;
      } else {
        return <i className="icon-magnifier-add"></i>;
      }
    }
    return <i className="icon-magnifier-add"></i>;
  };

  const madeWith = () => {
    if (framework) {
      return (
        <div>
          <img
            style={{ verticalAlign: "middle" }}
            src={images[framework]}
            width="150"
            height="150"
          />
          {/* <div style={{ verticalAlign: "middle", display: "inline" }}>
            Made with {framework}
          </div> */}
        </div>
      );
    }
  };

  const addVideo = () => {
    if (video) {
      return (
        <iframe
          width="560"
          height="315"
          src={"https://www.youtube.com/embed/" + video}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      );
    }
  };

  const addDownLoadButton = () => {
    return (
      <div className="mt-3">
        <a href={dDocument} className="btn btn-default" download>
          Download Document
        </a>
      </div>
    );
  };

  const addGitHub = () => {
    return (
      <div className="mt-3">
        <a href={gitHub} className="btn btn-default">
          GitHub
        </a>
      </div>
    );
  };

  return (
    <>
      <a
        href={link ? link : "!#"}
        className="work-image"
        onClick={handleLightbox}
      >
        <div className="portfolio-item rounded shadow-dark">
          <div className="details">
            <span className="term text-capitalize">{category}</span>
            <h4 className="title">{title}</h4>
            {/* <span className="more-button">{handleIcon()}</span> */}
            <span className="more-button">{madeWith()}</span>
          </div>
          <div className="thumb">
            <img src={image} alt="Portfolio-title" />
            <div className="mask"></div>
          </div>
        </div>
      </a>
      {popupLink && (
        <Modal
          open={toggler}
          onClose={handleLightbox}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {title}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {description}
            </Typography>
            {video && addVideo()}
            {gitHub && addGitHub()}
            {dDocument && addDownLoadButton()}
          </Box>
        </Modal>
      )}
    </>
  );
}

// {popupLink && <FsLightbox toggler={toggler} sources={popupLink} />}

export default Portfolio;
