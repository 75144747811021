import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 1,
    title: "Master's Degree in Software Engineering",
    years: "2019 - 2021",
    content: "Harvard University (HES)",
  },
  {
    id: 2,
    title: "Master's Certificate in Medical Physiology",
    years: "2018 - 2019",
    content: "University of Florida - Medical School",
  },
  {
    id: 3,
    title: "Bachelor's Degree in Communications",
    years: "2012 - 2016",
    content: "University of Massachusetts, Boston",
  },
];

const experienceData = [
  {
    id: 1,
    title: "Software Engineer",
    years: "July 2021 - Present",
    content:
      "I currently work as a full stack developer developing solutions for freelance clients.",
  },
  {
    id: 2,
    title: "Full Stack Web Developer",
    years: "May 2021 - May 2021",
    content: "Participated in a hack-a-thon at Texas Children's hospital.",
  },
  {
    id: 3,
    title: "Software Engineer",
    years: "May 2020 - July 2021",
    content: "Developed projects as team lead for various projects.",
  },
];

function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experience" />
        <div className="row">
          <div className="col-md-6">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
